<template>
  <div>
    <b-form name="bang-the-table-form" id="bang-the-table-form" autocomplete="off" @submit.prevent="onSubmitBangTheTableForm" novalidate>
      <b-form-row v-for="(input, index) in formInputList" :key="index">
        <b-col cols="12" v-if="input.key == 'BangTheTable-AdminUserID' && isEditMode">
          <b-form-group>
            <b-form-checkbox v-model="updateCredentialsChecked" @change="updateCredentials" name="update-password-checked">
              Update credentials
            </b-form-checkbox>
            <p class="mb-0 ml-4 font-14">Enable updating of credentials.</p>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                v-if="input.isStored && !updateCredentialsChecked && input.key == 'BangTheTable-AdminPassword'"
                :id="input.key"
                type="password"
                value="************"
                placeholder=" "
                autocomplete="new-password"
                disabled
              >
              </b-form-input>
              <b-form-input
                v-else
                :id="input.key"
                :type="input.key == 'BangTheTable-AdminPassword' ? 'password' : 'text'"
                placeholder=" "
                :disabled="isEditMode && input.key == 'BangTheTable-AdminUserID' && !updateCredentialsChecked"
                v-model.trim="input.value"
                :autocomplete="input.key == 'BangTheTable-AdminPassword' ? 'new-password' : 'off'"
                required
              ></b-form-input>
              <label :for="input.key">{{ input.label }}</label>
              <b-form-invalid-feedback class="d-block" v-if="!input.value && !input.isStored && formSubmitted"
                >{{ input.label }} required.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                class="d-block"
                v-if="!input.value && updateCredentialsChecked && formSubmitted && input.key == 'BangTheTable-AdminPassword'"
                >{{ input.label }} required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <b-button type="submit" v-activeBlur variant="primary" :disabled="saveLoadingIcon" @click="bangTheTableSidebarFg = true"
              >Save<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner>
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>
<script>
import { HTTP } from '@/utilities/http-common'
import cloneDeep from 'lodash/cloneDeep'
import { ServiceUrls } from '@/utilities/serviceUrls'
export default {
  name: 'BangTheTable',
  props: ['tenantServiceId'],
  data() {
    return {
      apiDefaultPayload: null,
      formSubmitted: false,
      saveLoadingIcon: false,
      isValidConfiguration: false,
      updateCredentialsChecked: false,
      isEditMode: false,
      savedConfigurations: [],
      defaultComponentId: '00000000-0000-0000-0000-000000000000',
      formInputList: [
        {
          id: null,
          label: 'Base domain',
          key: 'BangTheTable-BaseDomain',
          value: '',
          isStored: false,
          tenantProductGroupComponentId: '',
        },
        {
          id: null,
          label: 'API version',
          key: 'BangTheTable-APIVersion',
          value: '',
          isStored: false,
          tenantProductGroupComponentId: '',
        },
        {
          id: null,
          label: 'Admin userid',
          key: 'BangTheTable-AdminUserID',
          value: '',
          isStored: false,
          tenantProductGroupComponentId: '',
        },
        {
          id: null,
          label: 'Admin password',
          key: 'BangTheTable-AdminPassword',
          value: '',
          isStored: false,
          tenantProductGroupComponentId: null,
        },
      ],
    }
  },
  computed: {},
  mounted() {
    this.updatePayload().then(() => this.getBangTheTableConfiguration())
  },
  methods: {
    updatePayload() {
      return new Promise((resolve, reject) => {
        this.apiDefaultPayload = {
          productId: 'BangTheTable',
          tenantSvcId: this.tenantServiceId,
        }
        resolve()
      })
    },
    assignDefaultValue() {
      this.isEditMode = false
      this.formInputList.forEach((input) => {
        input.tenantId = this.tenantServiceId
        input.value = ''
        input.isStored = false
        input.tenantProductGroupComponentId = this.defaultComponentId
      })
    },
    getBangTheTableConfiguration() {
      return HTTP.post(ServiceUrls.getCredentialByTenantId, this.apiDefaultPayload)
        .then((res) => {
          this.updateCredentialsChecked = false
          if (res.data && res.data.data && res.data.data.length) {
            this.isEditMode = true
            this.formInputList.forEach((input) => {
              input.tenantId = this.tenantServiceId
              input.tenantProductGroupComponentId = this.defaultComponentId
              const inputObject = res.data.data.find((data) => data.key === input.key)
              if (inputObject) {
                input.value = inputObject.value
                input.isStored = inputObject.isStored
                input.tenantProductGroupComponentId = inputObject.tenantProductGroupComponentId
              }
            })
            this.savedConfigurations = cloneDeep(this.formInputList)
          } else {
            this.assignDefaultValue()
          }
          this.isValidConfiguration = !this.formInputList.filter((input) => !input.isStored && !input.value).length
          this.$emit('bangTheTableIsValidConfiguration', this.isValidConfiguration)
          return res
        })
        .catch((error) => {
          this.assignDefaultValue()
          this.updateCredentialsChecked = false
          this.$emit('bangTheTableIsValidConfiguration', false)
          return Promise.reject(error)
        })
    },
    updateCredentials() {
      const userIdIndex = this.formInputList.findIndex((input) => input.key === 'BangTheTable-AdminUserID')
      const passwordIndex = this.formInputList.findIndex((input) => input.key === 'BangTheTable-AdminPassword')
      this.formInputList[userIdIndex].value = this.savedConfigurations[userIdIndex].value
      this.formInputList[passwordIndex].value = this.savedConfigurations[passwordIndex].value
    },
    validateForm() {
      return !this.formInputList.some((input) => (this.updateCredentialsChecked ? !input.value : !input.isStored && !input.value))
    },
    onSubmitBangTheTableForm() {
      this.formSubmitted = true
      if (this.validateForm()) {
        this.saveLoadingIcon = true
        const payload = {
          credsPayload: this.apiDefaultPayload,
          configData: this.formInputList,
        }
        return HTTP.post(ServiceUrls.updateCredentialByTenantId, payload)
          .then((res) => {
            this.saveLoadingIcon = false
            if (res.data && res.data.data) {
              this.getBangTheTableConfiguration()
            }
            this.$emit('bangTheTableConfigUpdateRes', res.data)
            return res
          })
          .catch((error) => {
            this.saveLoadingIcon = false
            this.$emit('bangTheTableConfigUpdateRes', false)
            return Promise.reject(error)
          })
      }
    },
  },
  watch: {
    tenantServiceId(newValue, oldValue) {
      this.updateHeaders().then(() => this.getBangTheTableConfiguration())
    },
  },
}
</script>
